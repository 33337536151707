import React, { useState } from 'react'
import './Header.css'
import { Link } from 'react-scroll'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'


const Header=() =>{
  const mobile=window.innerWidth<=768?true:false;
  const [menuOpened,SetMenuOpened]=useState(false)
  return (
    <div className="header">
        <img src={Logo} alt="" className="logo"/>
        {(menuOpened===false&mobile===true)?(
          <div style={{
            backgroundColor:'var(--appColor)',
            padding:'0.5rem',
            borderRadius:'0.5rem',
            cursor:'pointer'
          }}
          onClick={()=> SetMenuOpened(true)}
          > <img src={Bars} alt='' style={{width:'1.5rem', height:'1.5rem'}}/></div>
        ):
        <ul className='header-menu'>
            <li><Link onClick={()=>SetMenuOpened(false)}
            activeClass='active'
            to='home'
            span={true}
            smooth={true}
            >Home </Link></li>
            <li> <Link onClick={()=>SetMenuOpened(false)}
              to='programs'
              span={true}
              smooth={true}>Programs</Link> </li>
            <li> <Link onClick={()=>SetMenuOpened(false)}
              to='reasons'
              span={true}
              smooth={true}
              >Why Us</Link></li>
            <li>
              <Link
              onClick={()=>SetMenuOpened(false)}
              to='plans'
              span={true}
              smooth={true}
              >Plans</Link></li>
            
        </ul>}
        
    </div>
  )
}

export default Header